<template>
  <div>
    <!-- Header -->
    <Header v-if="!checkWebview()"/>

    <section class="container">
      <div class="page-error" :class="{'padding-has-caution': isHasCaution}">
        <h1>予期せぬエラーが発生しました。</h1>
      </div>
    </section>

    <!-- Menu footer on SP -->
    <ListMenu class="hidden-pc"
              v-if="!checkWebview()"/>
  </div>
</template>

<script>
import Common from '@/mixins/common.mixin'
import Header from '@/layouts/Header'
import ListMenu from '@/components/ListMenu'
import { mapState } from 'vuex'

export default {
  name: 'PageError',

  components: { ListMenu, Header },

  mixins: [Common],

  created () {
    if (this.checkWebview()) {
      this.openPageNative('PageError')
    }
  },
  computed: {
    ...mapState('auth', ['profile']),
    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }
  }
}
</script>

<style lang="scss" scoped>
.page-error {
  text-align: center;
  padding: 90px 0;
  &.padding-has-caution {
    @media #{$info-phone} {
      padding: 120px 0 90px;
    }
  }
  h1 {
    @include font-size(24px);
    color: $default-green;
  }
}
</style>
